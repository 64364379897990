import _map from 'lodash/map';

import { FeaturesSectionProps, ImageWithAltText } from '../../../types/types';

export const processFeaturesSectionData = (data: any, page: string) => {
	const myData = data.allWpPage.edges[0].node[page][`${page}Featuressection`];
	const images = myData.imagearray;
	const imageArray: Array<ImageWithAltText> = [];
	images.forEach((imageField, index) => {
		if (imageField?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData) {
			imageArray.push({
				imageFile: imageField?.image?.imagefile.localFile?.childImageSharp?.gatsbyImageData,
				altText: imageField?.image?.alttext
			});
		}
	});
	const featuresSectionData: FeaturesSectionProps = {
		title: { ...myData.title },
		backgroundColor: myData.background.color,
		imageArray: imageArray
	};
	return featuresSectionData;
};

export default processFeaturesSectionData;
